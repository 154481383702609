/*=========
 Import Fonts 
=========*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans|Poppins&display=swap");

/*=========
 CSS font size
=========*/
/*=========
 CSS COLOR VARIABLES 
=========*/
/* NAVY Blue color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
.generalbtn {
  background-color: #122e43;
  color: #ffffff;
  border-radius: 3px;
  border: none;
}

.generalbtn:focus,
.generalbtn:hover {
  background-color: #2c5f85;
}

.color1 {
  color: #122e43 !important;
}

.color3 {
  color: #85adba;
}

.colorred {
  color: red !important;
}

.bg-color3 {
  background-color: #85adba;
}

/*=========
CSS STYLE FOR COMMON CLASSES
=========*/
a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

body,
html,
#root,
.Dashboard {
  height: 100%;
}

body {
  font-family: "PT Sans", sans-serif;
}

/* CSS STYLE FOR Dashboard */
.header-fixed {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99;
}

/*Strandenæs-Start*/
.header-Strandenæs {
  background-color: #eceff0;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .header-Strandenæs {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header-Strandenæs a {
  color: #122e43;
}

.header-Strandenæs .logo {
  width: 220px;
  height: 57px;
  display: inline-block;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .header-Strandenæs .logo {
    width: 180px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .header-Strandenæs .logo {
    width: 120px;
    height: 30px;
  }
}

.header-Strandenæs .togglebtn {
  display: none;
  background-color: #122e43;
  border: none;
}

.header-Strandenæs .togglebtn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .header-Strandenæs .togglebtn {
    display: block;
  }
}

.header-Strandenæs .userarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-Strandenæs .userarea .surveyheadicon {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
}

.header-Strandenæs .userarea .surveyheadicon span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: -7px;
  text-align: center;
  top: -2px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.header-Strandenæs .userarea .username {
  padding-left: 10px;
  font-size: 16px;
}

.header-Strandenæs .userarea .username a {
  color: #2c5f85;
}

@media screen and (max-width: 768px) {
  .header-Strandenæs .userarea {
    padding-right: 58px;
  }
}

.header-Strandenæs .userarea .userimg {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.header-Strandenæs .userarea .userimg img {
  max-width: 100%;
}

.main-container .pagettl-Strandenæs {
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
}

.main-container .sidebar-Strandenæs {
  background-color: #737373;
  -ms-flex-preferred-size: 250px;
  flex-basis: 250px;
  padding-top: 15px;
  height: 100%;
  min-width: 225px;
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Strandenæs {
    width: 0px;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    padding-top: 0px;
    min-width: 0px;
  }

  .main-container .sidebar-Strandenæs .sidebarMenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Strandenæs.sidebarOpen {
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
    width: 260px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 10;
    height: calc(100% - 60px);
  }

  .main-container .sidebar-Strandenæs.sidebarOpen .sidebarMenu {
    display: block;
    width: 100%;
  }
}

.main-container .sidebar-Strandenæs ul li a {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 15px;
  display: block;
  line-height: 30px;
}

.main-container .sidebar-Strandenæs ul li a:hover {
  color: black;
  background-color: #e5e5e5;
}

.main-container .sidebar-Strandenæs ul li a.active {
  background-color: #e5e5e5;
  color: black;
}

.main-container .banner-Strandenæs {
  background-color: #e5e5e5;
  height: 400px;
  padding: 30px 50px;
  background-image: linear-gradient(to right, rgba(18, 46, 67, 0) 0%, rgba(69, 21, 20, 0.85) 50%, #e5e5e5 100%),url("../../assets/images/db-Strandenæs-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-container .banner-Strandenæs:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 67, 0)), color-stop(50%, rgba(18, 46, 67, 0.85)), to(#122e43));
    background: linear-gradient(to bottom, rgba(18, 46, 67, 0) 0%, rgba(18, 46, 67, 0.85) 50%, #122e43 100%);
    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0)";
  }
}

.main-container .banner-Strandenæs h1 {
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .main-container .banner-Strandenæs h1 {
    font-size: 24px;
  }
}

.main-container .banner-Strandenæs p {
  font-size: 18px;
}

.main-container .banner-Strandenæs h1,
.main-container .banner-Strandenæs p {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.main-container .banner-Strandenæs button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 8px 20px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
}

.main-container .banner-Strandenæs .innerbaner {
  z-index: 9;
}

/*Strandenæs-End*/

/*Kreftforeningen-Start*/
.header-Kreftforeningen {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .header-Kreftforeningen {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header-Kreftforeningen a {
  color: #122e43;
}

.header-Kreftforeningen .logo {
  width: 220px;
  height: 57px;
  display: inline-block;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .header-Kreftforeningen .logo {
    width: 180px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .header-Kreftforeningen .logo {
    width: 120px;
    height: 30px;
  }
}

.header-Kreftforeningen .togglebtn {
  display: none;
  background-color: #122e43;
  border: none;
}

.header-Kreftforeningen .togglebtn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .header-Kreftforeningen .togglebtn {
    display: block;
  }
}

.header-Kreftforeningen .userarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-Kreftforeningen .userarea .surveyheadicon {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
}

.header-Kreftforeningen .userarea .surveyheadicon span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: -7px;
  text-align: center;
  top: -2px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.header-Kreftforeningen .userarea .username {
  padding-left: 10px;
  font-size: 16px;
}

.header-Kreftforeningen .userarea .username a {
  color: #2c5f85;
}

@media screen and (max-width: 768px) {
  .header-Kreftforeningen .userarea {
    padding-right: 58px;
  }
}

.header-Kreftforeningen .userarea .userimg {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.header-Kreftforeningen .userarea .userimg img {
  max-width: 100%;
}

.main-container .sidebar-Kreftforeningen {
  background-color: #333333;
  -ms-flex-preferred-size: 250px;
  flex-basis: 250px;
  padding-top: 15px;
  height: 100%;
  min-width: 225px;
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Kreftforeningen {
    width: 0px;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    padding-top: 0px;
    min-width: 0px;
  }

  .main-container .sidebar-Kreftforeningen .sidebarMenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Kreftforeningen.sidebarOpen {
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
    width: 260px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 10;
    height: calc(100% - 60px);
  }

  .main-container .sidebar-Kreftforeningen.sidebarOpen .sidebarMenu {
    display: block;
    width: 100%;
  }
}

.main-container .sidebar-Kreftforeningen ul li a {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 15px;
  display: block;
  line-height: 30px;
}

.main-container .sidebar-Kreftforeningen ul li a:hover {
  background-color: #2c5f85;
}

.main-container .sidebar-Kreftforeningen ul li a.active {
  background-color: #2c5f85;
}

.main-container .banner-Kreftforeningen {
  background-color: #333333;
  height: 400px;
  padding: 30px 50px;
  background-image: url("../../assets/images/db-Kreftforeningen-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-container .banner-Kreftforeningen:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 67, 0)), color-stop(50%, rgba(18, 46, 67, 0.85)), to(#122e43));
    background: linear-gradient(to bottom, rgba(18, 46, 67, 0) 0%, rgba(18, 46, 67, 0.85) 50%, #122e43 100%);
    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0)";
  }
}

.main-container .banner-Kreftforeningen h1 {
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .main-container .banner-Kreftforeningen h1 {
    font-size: 24px;
  }
}

.main-container .banner-Kreftforeningen p {
  font-size: 18px;
}

.main-container .banner-Kreftforeningen h1,
.main-container .banner-Kreftforeningen p {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.main-container .banner-Kreftforeningen button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 8px 20px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
}

.main-container .banner-Kreftforeningen .innerbaner {
  z-index: 9;
}

/*Kreftforeningen-End*/

/*Insa-Start*/
.header-Insa {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .header-Insa {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header-Insa a {
  color: #122e43;
}

.header-Insa .logo {
  width: 220px;
  height: 57px;
  display: inline-block;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .header-Insa .logo {
    width: 180px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .header-Insa .logo {
    width: 120px;
    height: 30px;
  }
}

.header-Insa .togglebtn {
  display: none;
  background-color: #122e43;
  border: none;
}

.header-Insa .togglebtn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .header-Insa .togglebtn {
    display: block;
  }
}

.header-Insa .userarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-Insa .userarea .surveyheadicon {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
}

.header-Insa .userarea .surveyheadicon span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: -7px;
  text-align: center;
  top: -2px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.header-Insa .userarea .username {
  padding-left: 10px;
  font-size: 16px;
}

.header-Insa .userarea .username a {
  color: #2c5f85;
}

@media screen and (max-width: 768px) {
  .header-Insa .userarea {
    padding-right: 58px;
  }
}

.header-Insa .userarea .userimg {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.header-Insa .userarea .userimg img {
  max-width: 100%;
}

.main-container .sidebar-Insa {
  background-color: #500e6a;
  -ms-flex-preferred-size: 250px;
  flex-basis: 250px;
  padding-top: 15px;
  height: 100%;
  min-width: 225px;
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Insa {
    width: 0px;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    padding-top: 0px;
    min-width: 0px;
  }

  .main-container .sidebar-Insa .sidebarMenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Insa.sidebarOpen {
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
    width: 260px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 10;
    height: calc(100% - 60px);
  }

  .main-container .sidebar-Insa.sidebarOpen .sidebarMenu {
    display: block;
    width: 100%;
  }
}

.main-container .sidebar-Insa ul li a {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 15px;
  display: block;
  line-height: 30px;
}

.main-container .sidebar-Insa ul li a:hover {
  color: black;
  background-color: #e1c8ff;
}

.main-container .sidebar-Insa ul li a.active {
  color: black;
  background-color: #e1c8ff;
}

.main-container .banner-Insa {
  background-color: #500e6a;
  height: 400px;
  padding: 30px 50px;
  background-image: url("../../assets/images/db-banner-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-container .banner-Insa:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 67, 0)), color-stop(50%, rgba(18, 46, 67, 0.85)), to(#122e43));
    background: linear-gradient(to bottom, rgba(18, 46, 67, 0) 0%, rgba(18, 46, 67, 0.85) 50%, #122e43 100%);
    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0)";
  }
}

.main-container .banner-Insa h1 {
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .main-container .banner-Insa h1 {
    font-size: 24px;
  }
}

.main-container .banner-Insa p {
  font-size: 18px;
}

.main-container .banner-Insa h1,
.main-container .banner-Insa p {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.main-container .banner-Insa button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 8px 20px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
}

.main-container .banner-Insa .innerbaner {
  z-index: 9;
}

/*Insa-End*/

/*Avant-IT-Start*/
.header-AvantIT {
  background-color: #eceff0;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .header-AvantIT {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header-AvantIT a {
  color: #122e43;
}

.header-AvantIT .logo {
  width: 220px;
  height: 57px;
  display: inline-block;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .header-AvantIT .logo {
    width: 180px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .header-AvantIT .logo {
    width: 120px;
    height: 30px;
  }
}

.header-AvantIT .togglebtn {
  display: none;
  background-color: #122e43;
  border: none;
}

.header-AvantIT .togglebtn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .header-AvantIT .togglebtn {
    display: block;
  }
}

.header-AvantIT .userarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-AvantIT .userarea .surveyheadicon {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
}

.header-AvantIT .userarea .surveyheadicon span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: -7px;
  text-align: center;
  top: -2px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.header-AvantIT .userarea .username {
  padding-left: 10px;
  font-size: 16px;
}

.header-AvantIT .userarea .username a {
  color: #2c5f85;
}

@media screen and (max-width: 768px) {
  .header-AvantIT .userarea {
    padding-right: 58px;
  }
}

.header-AvantIT .userarea .userimg {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.header-AvantIT .userarea .userimg img {
  max-width: 100%;
}

.main-container .sidebar-AvantIT {
  background-color: #122e43;
  -ms-flex-preferred-size: 250px;
  flex-basis: 250px;
  padding-top: 15px;
  height: 100%;
  min-width: 225px;
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-AvantIT {
    width: 0px;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    padding-top: 0px;
    min-width: 0px;
  }

  .main-container .sidebar-AvantIT .sidebarMenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-AvantIT.sidebarOpen {
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
    width: 260px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 10;
    height: calc(100% - 60px);
  }

  .main-container .sidebar-AvantIT.sidebarOpen .sidebarMenu {
    display: block;
    width: 100%;
  }
}

.main-container .sidebar-AvantIT ul li a {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 15px;
  display: block;
  line-height: 30px;
}

.main-container .sidebar-AvantIT ul li a:hover {
  background-color: #2c5f85;
}

.main-container .sidebar-AvantIT ul li a.active {
  background-color: #2c5f85;
}

.main-container .banner-AvantIT {
  background-color: #122e43;
  height: 400px;
  padding: 30px 50px;
  background-image: url("../../assets/images/db-banner-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-container .banner-AvantIT:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 67, 0)), color-stop(50%, rgba(18, 46, 67, 0.85)), to(#122e43));
    background: linear-gradient(to bottom, rgba(18, 46, 67, 0) 0%, rgba(18, 46, 67, 0.85) 50%, #122e43 100%);
    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0)";
  }
}

.main-container .banner-AvantIT h1 {
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .main-container .banner-AvantIT h1 {
    font-size: 24px;
  }
}

.main-container .banner-AvantIT p {
  font-size: 18px;
}

.main-container .banner-AvantIT h1,
.main-container .banner-AvantIT p {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.main-container .banner-AvantIT button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 8px 20px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
}

.main-container .banner-AvantIT .innerbaner {
  z-index: 9;
}

/*AvantIT-End*/
/*Osloadvokatene-Start*/
.header-Osloadvokatene {
  background-color: #eceff0;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .header-Osloadvokatene {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header-Osloadvokatene a {
  color: #122e43;
}

.header-Osloadvokatene .logo {
  width: 220px;
  height: 57px;
  display: inline-block;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .header-Osloadvokatene .logo {
    width: 180px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .header-Osloadvokatene .logo {
    width: 120px;
    height: 30px;
  }
}

.header-Osloadvokatene .togglebtn {
  display: none;
  background-color: #122e43;
  border: none;
}

.header-Osloadvokatene .togglebtn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .header-Osloadvokatene .togglebtn {
    display: block;
  }
}

.header-Osloadvokatene .userarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-Osloadvokatene .userarea .surveyheadicon {
  font-size: 24px;
  margin-right: 20px;
  position: relative;
}

.header-Osloadvokatene .userarea .surveyheadicon span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: -7px;
  text-align: center;
  top: -2px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.header-Osloadvokatene .userarea .username {
  padding-left: 10px;
  font-size: 16px;
}

.header-Osloadvokatene .userarea .username a {
  color: #2c5f85;
}

@media screen and (max-width: 768px) {
  .header-Osloadvokatene .userarea {
    padding-right: 58px;
  }
}

.header-Osloadvokatene .userarea .userimg {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.header-Osloadvokatene .userarea .userimg img {
  max-width: 100%;
}

.main-container .sidebar-Osloadvokatene {
  background-color: #122e43;
  -ms-flex-preferred-size: 250px;
  flex-basis: 250px;
  padding-top: 15px;
  height: 100%;
  min-width: 225px;
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Osloadvokatene {
    width: 0px;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    padding-top: 0px;
    min-width: 0px;
  }

  .main-container .sidebar-Osloadvokatene .sidebarMenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebar-Osloadvokatene.sidebarOpen {
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
    width: 260px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 10;
    height: calc(100% - 60px);
  }

  .main-container .sidebar-Osloadvokatene.sidebarOpen .sidebarMenu {
    display: block;
    width: 100%;
  }
}

.main-container .sidebar-Osloadvokatene ul li a {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 15px;
  display: block;
  line-height: 30px;
}

.main-container .sidebar-Osloadvokatene ul li a:hover {
  background-color: #2c5f85;
}

.main-container .sidebar-Osloadvokatene ul li a.active {
  background-color: #2c5f85;
}

.main-container .banner-Osloadvokatene {
  background-color: #122e43;
  height: 400px;
  padding: 30px 50px;
  background-image: url("../../assets/images/db-banner-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-container .banner-Osloadvokatene:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 67, 0)), color-stop(50%, rgba(18, 46, 67, 0.85)), to(#122e43));
    background: linear-gradient(to bottom, rgba(18, 46, 67, 0) 0%, rgba(18, 46, 67, 0.85) 50%, #122e43 100%);
    filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0)";
  }
}

.main-container .banner-Osloadvokatene h1 {
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .main-container .banner-Osloadvokatene h1 {
    font-size: 24px;
  }
}

.main-container .banner-Osloadvokatene p {
  font-size: 18px;
}

.main-container .banner-Osloadvokatene h1,
.main-container .banner-Osloadvokatene p {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.main-container .banner-Osloadvokatene button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 8px 20px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
}

.main-container .banner-Osloadvokatene .innerbaner {
  z-index: 9;
}

/*Osloadvokatene-End*/
.main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  height: calc(100% - 80px);
  /* chat message start */
  /* chat message start */
}

@media screen and (max-width: 768px) {
  .main-container {
    height: calc(100% - 60px);
  }
}

.main-container .sidebarMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .main-container .sidebarMobile {
    display: block;
    position: absolute;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.05);
    top: 10px;
    right: 10px;
    border-radius: 5px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
  }
}

@media screen and (max-width: 768px) and (max-width: 480px) {
  .main-container .sidebarMobile {
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
  }
}

@media screen and (max-width: 768px) {
  .main-container .sidebarMobile li button {
    color: #122e43;
    padding: 8px 10px !important;
    border-radius: 5px;
    background-color: transparent;
    border: none;
  }

  .main-container .sidebarMobile li button:focus {
    color: #122e43;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
  }
}

.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #122e43;
  border-radius: 3px;
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.main-container .content-area {
  padding-left: 20px;
  overflow: auto;
  width: 100%;
}

.main-container .content-area .generalbox {
  background-color: #eceff0;
  height: 100%;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .main-container .content-area .generalbox {
    height: auto;
    padding: 20px !important;
  }
}

.main-container .content-area .generalbox h2 {
  color: #2c5f85;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.main-container .content-area .generalbox h3 {
  color: #2c5f85;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.main-container .content-area .generalbox .case-point-list {
  min-width: 140px;
}

@media screen and (max-width: 1200px) {
  .main-container .content-area .generalbox .case-point-list {
    min-width: auto;
    padding-right: 15px;
  }
}

.main-container .content-area .generalbox .case-point-list span {
  text-transform: uppercase;
  color: #666;
  font-size: 14px;
}

.main-container .content-area .generalbox .case-lawyer img {
  width: 100px;
  border: 2px solid #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.main-container .content-area .generalbox .border-btn {
  border-color: #122e43 !important;
  color: #122e43 !important;
}

.main-container .content-area .generalbox .general-list li {
  font-size: 16px;
  color: "PT Sans", sans-serif;
  padding: 15px 0px;
  border-bottom: 1px solid #ffffff;
}

.main-container .content-area .generalbox .general-list li h4 {
  font-size: 16px;
  color: #2c5f85;
  font-weight: 600;
}

.main-container .content-area .generalttl {
  color: #2c5f85;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.main-container .content-area .multipleNews .newsimg {
  min-height: auto;
}

.main-container .content-area .multipleNews .newsimg img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.main-container .content-area .newsblock {
  margin-bottom: 30px;
}

.main-container .content-area .newsblock .newsimg {
  min-height: 300px;
}

.main-container .content-area .newsblock .newsimg img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .main-container .content-area .newsblock .newsimg {
    min-height: auto;
  }
}

.main-container .content-area .newsblock .timestamp {
  font-size: 12px;
  color: #666;
}

.main-container .content-area .newsblock h3 {
  font-size: 20px;
}

.main-container .content-area .newsblock h3 a {
  color: #2c5f85;
}

.main-container .pagettl {
  color: #122e43;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
}

.main-container .pagettlSmall {
  color: #122e43;
  font-weight: 600;
  font-size: 20px;
}

.main-container .unOpened-document,
.main-container .unOpened-messages {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  position: relative;
}

.main-container .unOpened-document span,
.main-container .unOpened-messages span {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: -5px;
  border-radius: 50%;
  line-height: 15px;
  width: 15px;
  height: 15px;
}

.main-container .content-area-details {
  padding: 25px 10px;
  width: 100%;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.main-container .content-area-details .case-title {
  font-size: 22px;
  color: #2c5f85;
}

.main-container .content-area-details .item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: #eceff0;
  padding: 20px;
  -ms-flex-preferred-size: calc(50% - 40px);
  flex-basis: calc(50% - 40px);
  margin: 0px 15px 25px 15px;
  font-size: 16px;
  color: #333;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1200px) {
  .main-container .content-area-details .item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.main-container .content-area-details .item a p {
  color: #333;
}

.main-container .content-area-details .item.first-item {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

@media screen and (max-width: 991px) {
  .main-container .content-area-details .item.first-item ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .main-container .content-area-details .item.first-item ul li {
    margin-bottom: 15px;
  }
}

.main-container .content-area-details .item h2 {
  font-size: 20px;
  color: #122e43;
}

.main-container .content-area-details .item h3 {
  font-size: 18px;
  color: #2c5f85;
}

.main-container .content-area-details .item .case-point-list {
  min-width: 180px;
}

@media screen and (max-width: 1200px) {
  .main-container .content-area-details .item .case-point-list {
    min-width: auto;
    padding-right: 15px;
  }
}

.main-container .content-area-details .item .case-point-list span {
  color: #666;
}

.main-container .content-area-details .item .case-point-list.case-lawyer img {
  width: 120px;
  border: 2px solid #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1200px) {
  .main-container .content-area-details .item .case-point-list.case-lawyer img {
    width: 80px;
  }
}

.main-container .message-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.main-container .message-head p {
  margin-bottom: 0;
}

.main-container .message-head .message-btn {
  min-width: 125px;
  margin-left: auto !important;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .main-container .message-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main-container .message-head .message-btn {
    margin-left: 0 !important;
    margin-top: 20px;
  }
}

.main-container .chat-main-container {
  padding: 25px;
  overflow: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .main-container .chat-main-container .chatheight {
    height: auto !important;
  }
}

.main-container .chat-message-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 271px);
  border-radius: 5px;
  background-color: #ffffff;
  border: 2px solid #dddddd;
}

@media screen and (max-width: 768px) {
  .main-container .chat-message-box {
    height: calc(100vh - 350px);
  }
}

.main-container .chat-message-box .message-header {
  border-bottom: 1px solid #dddddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-container .chat-message-box .message-header h4 {
  font-size: 18px;
}

.main-container .chat-message-box .message-header h6 {
  font-size: 16px;
  margin-bottom: 0;
}

.main-container .chat-message-box .message-chat-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main-container .chat-message-box .message-chat-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-container .chat-message-box .message-chat-container li {
  color: #122e43;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.main-container .chat-message-box .message-chat-container li .message-user-img {
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #122e43;
}

.main-container .chat-message-box .message-chat-container li .message-user-img img {
  max-width: 100%;
}

.main-container .chat-message-box .message-chat-container li .message-chat-box {
  text-align: left;
  background-color: #eceff0;
  padding: 10px;
  border-radius: 3px;
  position: relative;
  max-width: 85%;
  min-width: 200px;
}

@media screen and (max-width: 767px) {
  .main-container .chat-message-box .message-chat-container li .message-chat-box {
    max-width: 75%;
  }
}

.main-container .chat-message-box .message-chat-container li .message-time {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: #666666;
}

.main-container .chat-message-box .message-chat-container li h4 {
  font-size: 16px;
  font-weight: 700;
  margin-right: 95px;
}

.main-container .chat-message-box .message-chat-container li p {
  font-size: 14px;
  margin-bottom: 0;
}

.main-container .chat-message-box .message-chat-container li.message-chat-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.main-container .chat-message-box .message-chat-container li.message-chat-right .message-user-img {
  margin-right: 0;
  margin-left: 10px;
}

.main-container .chat-message-box .message-chat-container li.message-chat-right p {
  margin-top: 28px;
}

.main-container .chat-message-box .message-chat-container li.message-chat-right .message-chat-box {
  background-color: #addcff;
}

.main-container .chat-message-box .message-chat-container li.message-date {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.main-container .chat-message-box .message-chat-container li.message-date:before {
  content: '';
  border-top: 2px solid #dddddd;
  margin: 0 20px 0 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20px;
  flex: 1 0 20px;
}

.main-container .chat-message-box .message-chat-container li.message-date:after {
  content: '';
  border-top: 2px solid #dddddd;
  margin: 0 0 0 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20px;
  flex: 1 0 20px;
}

.main-container .chat-message-box .message-footer {
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  border-top: 1px solid #dddddd;
  position: relative;
}

.main-container .chat-message-box .message-footer textarea.form-control {
  border: 0;
  border-radius: 0;
  padding: 20px 80px 20px 20px;
  resize: none;
}

.main-container .chat-message-box .message-footer .message-send-btn {
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  height: 44px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 44px;
  background-color: #122e43;
  line-height: 44px;
  text-align: center;
}

.main-container .MessageList li {
  position: relative;
  padding: 10px 40px 10px 10px;
  border-bottom: 1px solid #d6d6d6;
}

.main-container .MessageList li.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.main-container .MessageList li .messageicon {
  position: absolute;
  right: 10px;
  top: 30px;
}
.main-container .MessageList li .surveymessageicon {
  position: absolute;
  right: 10px;
}
.main-container .MessageList li p {
  margin: 0px;
  line-height: 22px;
  font-size: 14px;
}

.main-container .MessageList li p a {
  color: #122e43;
}

.main-container .smallbtn {
  font-size: 14px;
  background-color: #122e43;
  padding: 5px 10px;
  border-radius: 3px;
  color: #ffffff;
}

.main-container .documents_list li {
  position: relative;
  padding: 10px 30px;
  border-bottom: 1px solid #d6d6d6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .main-container .documents_list li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.main-container .documents_list li .documentCategory {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -8px;
}

.main-container .documentDownload {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -10px;
}

.main-container .timesheetlist li {
  padding-left: 50px;
  padding-right: 100px;
}

@media screen and (max-width: 400px) {
  .main-container .timesheetlist li {
    padding-right: 0px;
  }

  .main-container .timesheetlist li .documentDownload {
    position: relative;
    margin-top: 5px;
  }

  .main-container .timesheetlist li .documentDownload span {
    display: inline-block;
  }

  .main-container .timesheetlist li .TimeSnap {
    padding: 2px 10px;
    font-size: 14px;
  }
}

.main-container .timesheetlist li .DateSnap {
  position: absolute;
  left: 0px;
}

.main-container .DateSnap {
  background-color: #c4dae1;
  padding: 4px 10px;
  line-height: 18px;
  font-size: 16px;
  display: inline-block;
  color: #122e43;
  margin-right: 20px;
  -webkit-box-shadow: 0px 1px 1px #122e43;
  box-shadow: 0px 1px 1px #122e43;
}

.main-container .TimeSnap {
  background-color: #c4dae1;
  padding: 8px 10px;
  font-size: 18px;
  color: #122e43;
  margin-right: 20px;
  -webkit-box-shadow: 0px 1px 1px #122e43;
  box-shadow: 0px 1px 1px #122e43;
}

.main-container .fileUploader {
  border: 1px dashed #122e43;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0px !important;
}

.fileUploadLabel {
  padding: 0px;
  max-width: 100%;
}

@media screen and (max-width: 480px) {
  .main-container .fileUploader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.main-container .fileUploader button {
  background-color: #c4dae1;
  font-size: 14px;
  color: #122e43;
  text-transform: uppercase;
  border: none;
  padding: 7px 10px;
  border-radius: 2px;
}

.main-container .invoiced li {
  color: #0f7a14;
  line-height: 24px;
}

.main-container .invoiced li span {
  display: block;
}

.main-container .notinvoiced li {
  color: #b60808;
  line-height: 24px;
}

.main-container .notinvoiced li span {
  display: block;
}

.main-container .profilelist li {
  margin-bottom: 15px;
  font-size: 18px;
}

.main-container .profilelist li span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
}

.main-container .profilelist li button {
  background-color: #c4dae1;
  font-size: 14px;
  color: #122e43;
  text-transform: uppercase;
  border: none;
  padding: 7px 10px;
  border-radius: 2px;
}

.main-container .profilelist li .profileimg {
  margin-bottom: 20px;
  width: 150px;
  max-width: 100%;
  height: auto;
}

.main-container .primarybtn {
  background-color: #122e43;
  border: none;
}

.main-container .consent .generalbox {
  height: auto;
}

.main-container .consent .generalbox .concentinput {
  width: 350px;
}

@media screen and (max-width: 480px) {
  .main-container .consent .generalbox .concentinput {
    width: 100%;
  }
}

.main-container .consent .switch.btn.on {
  border-color: #122e43;
  background-color: #122e43 !important;
}

.main-container .consent .switch.btn.on .switch-on {
  background-color: #122e43 !important;
}

.progresssteps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.progresssteps ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.progresssteps ul li {
  min-width: 120px;
  text-align: center;
  position: relative;
  height: 40px;
}

@media screen and (max-width: 480px) {
  .progresssteps ul li {
    min-width: 90px;
  }
}

.progresssteps ul li a {
  color: #333;
}

@media screen and (max-width: 480px) {
  .progresssteps ul li a {
    font-size: 13px;
  }
}

.progresssteps ul li a span {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #c0c0c0;
  display: inline-block;
  left: 50%;
  margin-left: -10px;
  bottom: -8px;
  z-index: 1;
}

.progresssteps ul li.active span {
  background-color: #044f9c;
}

.progresssteps ul li.active:after {
  background-color: #044f9c;
}

.progresssteps ul li:after {
  height: 5px;
  border-radius: 5px;
  background-color: #c0c0c0;
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.progressform label {
  color: #122e43;
  font-size: 16px;
}

.progressform .form-control {
  border: 1px solid #dddddd;
  padding: 8px 10px;
  resize: none;
  min-height: 45px;
}

.progressform textarea {
  resize: none;
}

.progressform textarea:focus {
  outline: none;
}

.progressform td img {
  border: 2px solid #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  width: 80px;
}

.progressform td p {
  margin-bottom: 3px;
}

.progresstable {
  border: 1px solid #b1cad2;
}

.progresstable tr td {
  border-top: 1px solid #b1cad2;
}

.progresstable th {
  background-color: #b1cad2;
  padding: 5px 10px;
  border-bottom: none !important;
  text-shadow: none;
  font-weight: normal;
  font-size: 14px;
}

.verifyinfo label {
  text-transform: uppercase;
  color: #044f9c;
  font-size: 15px;
  margin-bottom: 3px;
  font-weight: bold;
}

.verifyinfo .selctedlawyer {
  background-color: rgba(177, 202, 210, 0.3);
  padding: 10px 10px 10px 80px;
  border-radius: 5px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .verifyinfo .selctedlawyer {
    padding-top: 15px;
    padding-left: 10px;
  }
}

.verifyinfo .selctedlawyer img {
  width: 60px;
  position: absolute;
  left: 10px;
}

@media screen and (max-width: 768px) {
  .verifyinfo .selctedlawyer img {
    position: relative;
    left: 15px;
    margin-bottom: 15px;
  }
}

.verifyinfo .selctedlawyer p {
  margin-bottom: 3px;
}

@media screen and (max-width: 768px) {
  .verifyinfo .selctedlawyer p {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 768px) {
  table.responsive thead {
    display: none;
  }

  table.responsive tr {
    display: block;
    position: relative;
    padding-top: 0px;
    border-bottom: 1px solid #b1cad2 !important;
  }

  table.responsive td:before {
    content: attr(data-label);
    text-transform: uppercase;
    width: 100%;
    font-size: 14px;
    position: absolute;
    left: 13px;
    top: 10px;
    text-align: left;
    font-weight: bold;
    color: #333;
  }

  table.responsive td {
    display: block;
    text-align: left !important;
    width: auto;
    border-top: none !important;
    position: relative;
    padding-top: 40px;
  }

  table.responsive td:nth-child(1),
  table.responsive td:nth-child(2) {
    padding-top: 5px;
  }
}

.loaderScreen {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #122e43;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  /* Safari */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.agree-terms {
  top: 0px !important;
  margin-right: 10px;
}

.flag-select {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 10px 0px 10px;
  margin-right: 15px;
}

.flag-select button {
  margin: 0px;
  padding: 0px 15px 0px 0px;
  position: relative;
}

.flag-select button:after {
  position: absolute;
  right: -3px;
  top: 47%;
}

.flag-select button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flag-select button .flag-select__option {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flag-select button .flag-select__option img {
  top: 0px;
  width: auto;
}

.flag-select .flag-select__options {
  margin: 5px 0px 0px 0px;
  padding: 0px;
}

.flag-select .flag-select__options li {
  font-size: 16px;
}

.flag-select .flag-select__options li img {
  top: 0px;
}

@media screen and (max-width: 640px) {
  .flag-select .flag-select__option img {
    max-width: 60px;
  }

  .flag-select .flag-select__option__label {
    display: none;
  }
}

.usernameDropdown {
  background: none;
  border: none;
  color: #333 !important;
  padding: 0px !important;
}

.usernameDropdown:focus,
.usernameDropdown:hover {
  background: none !important;
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #333 !important;
}

@media screen and (max-width: 768px) {
  .usernameDropdown span {
    display: none;
  }
}

.toast-success-style {
  background-color: #e8f5e9;
}

.toast-success-body {
  color: #33691e;
}

.toast-success-progress {
  background: #33691e;
}

.alignedBox {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.moreText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.newbage {
  color: #ffffff;
  background-color: #122e43;
}

.main-container .MessageList li.unreadmessage {
  background-color: rgba(255, 255, 255, 0.7);
}

.spanbadge {
  background-color: #de0d0d;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 14px;
  border-radius: 50%;
  line-height: 24px;
  width: 24px;
  height: 24px;
}

/* Survey start */
.survey-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid #eceff0;
}

.survey-head p {
  margin-bottom: 0;
}

.survey-head .survey-btn {
  min-width: 125px;
  margin-left: auto !important;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .survey-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .survey-head .survey-btn {
    margin-left: 0 !important;
    margin-top: 20px;
  }
}

.survey-main-container {
  padding: 25px;
  overflow: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.survey-main-container .surveyquestions {
  padding-bottom: 30px;
  border-bottom: 1px solid #eceff0;
}

.survey-main-container .surveyquestions p {
  font-size: 18px;
  color: #122e43;
}

.survey-main-container .surveyquestions p span {
  color: #ff0000;
  padding-left: 2px;
}

.survey-main-container .surveyquestions .form-check-label {
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
  cursor: pointer;
}

.survey-main-container .surveyquestions textarea.form-control {
  resize: none;
}

.survey-main-container .surveyquestions .surveyinfoicon {
  margin-left: 5px;
}

.survey-main-container .surveyquestions .Surveydatecontainer {
  position: relative;
}

.survey-main-container .surveyquestions .Surveydatecontainer .react-datepicker-wrapper {
  width: 100%;
}

.survey-main-container .surveyquestions .Surveydatecontainer .react-datepicker-wrapper .surverydatetimepicker {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
}

.survey-main-container .surveyquestions .Surveydatecontainer .calendericon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eeeeee;
  line-height: 34px;
  padding: 2px 13px;
  border: 1px solid #ced4da;
  border-radius: 0 2px 2px 0;
}

.survey-main-container .surveybtncontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}

.survey-main-container .surveybtncontainer .survey-btn {
  min-width: 125px;
  text-align: center;
}

.survey-main-container .surveybtncontainer .skip-btn {
  min-width: 125px;
  text-align: center;
  background-color: #eceff0;
  color: #333333;
  margin-right: 20px;
}

.survey-main-container .surveynumber {
  padding-left: 0;
}

.survey-main-container .surveynumber input {
  width: 100%;
  margin-left: 0;
  height: 40px;
  border: 1px solid #ced4da;
}

.survey-main-container .surveysinglelinetext input {
  width: 100%;
  margin-left: 0;
  height: 40px;
  border: 1px solid #ced4da;
}

.survey-main-container .surveynumber input[type=number]::-webkit-inner-spin-button,
.survey-main-container .surveynumber input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.survey-main-container .surveysinglelinetext input[type=text]::-webkit-inner-spin-button,
.survey-main-container .surveysinglelinetext input[type=text]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.successmessage{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.successicon{
  color: forestgreen;
  height: 90px;
  width: 90px !important;
}
/* Survey End */
.newDocument {
  background-color: rgba(255, 255, 255, 0.7);
}
.removePadding{
  padding: 0%;
}
/* Create Case */
.creat-form-center{
  justify-content: center;
}
.create-case-ttl{
  color: #2e4a74;
  padding: 2rem;
}
.full-background {
  background-color: #eceff0;
  min-height: 100vh; 
  width: 100%; 
}
.caseFileDrop{
  display: flex;
  justify-content: center;
  align-items: center;
      border: 1px dashed #122e43;
      padding: 20px;
      flex-direction: column;
}
.fileDropd{
  padding: 2rem;
}
.fileDropd:hover{
  cursor: pointer;
  background: #c2f3c2;
  
}