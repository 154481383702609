/*=========
 Import Fonts 
=========*/
@import url("https://fonts.googleapis.com/css?family=PT+Sans|Poppins&display=swap");
/*=========
 CSS font size
=========*/
/*=========
 CSS COLOR VARIABLES 
=========*/
/* NAVY Blue color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
/* Very light Blue/Gray color */
/*=========
CSS STYLE FOR COMMON CLASSES
=========*/
a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

body,
html {
  height: 100%;
}

body {
  font-family: "PT Sans", sans-serif;
}

.login-body {
  padding-top: 0px;
}
/*AvantIT Login Page CSS-Start*/
.login-window-AvantIT {
  background-color: #122e43;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-window-AvantIT .login-redirect-area {
  width: 640px;
}

@media screen and (max-width: 680px) {
  .login-window-AvantIT .login-redirect-area {
    width: 90%;
  }
  .login-window-AvantIT .login-redirect-area img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .login-window-AvantIT{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0px;
    min-height: 100%;
    height: auto;
  }
}
.login-window-AvantIT .login-area {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 930px;
}

@media screen and (max-width: 940px) {
  .login-window-AvantIT .login-area {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .login-window-AvantIT .login-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.login-window-AvantIT .login-area .login-column,
.login-window-AvantIT .login-area .news-column {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .login-window-AvantIT .login-area .login-column,
  .login-window-AvantIT .login-area .news-column {
    width: 100%;
  }
}

.login-window-AvantIT .login-area .login-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  padding: 40px 40px 20px 40px;
}

@media screen and (max-width: 768px) {
  .login-window-AvantIT .login-area .login-column {
    padding: 40px 30px 20px 30px;
  }
}

.login-window-AvantIT .login-area .login-column .vippsbtn {
  background: none;
  border: none;
}

.login-window-AvantIT .login-area .login-column .vippsbtn:focus, .login-window .login-area .login-column .vippsbtn:hover {
  background: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.login-window-AvantIT .login-area .login-column p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.login-window-AvantIT .login-area .login-column p.fnt-14 {
  font-size: 14px;
}

.login-window-AvantIT .login-area .login-column p.fnt-14 a {
  color: #122e43;
}

.login-window-AvantIT .login-area .login-column button {
  background-color: #fe5a22;
  padding: 15px 0px;
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .login-window-AvantIT .login-area .login-column button {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .login-window-AvantIT .login-area .login-column button {
    width: 100%;
  }
}

.login-window-AvantIT .login-area .news-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px 20px 40px;
  background-color: #2c5f85;
  border-radius: 0px 4px 4px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 768px) {
  .login-window-AvantIT .login-area .news-column {
    padding: 30px 20px;
    border-radius: 0px 0px 4px 4px;
  }
}

.login-window-AvantIT .login-area .news-column h3 {
  font-size: 20px;
  color: #ffffff;
}

.login-window-AvantIT .login-area .news-column .newslist li {
  position: relative;
  padding-left: 207px;
  height: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .login-window-AvantIT .login-area .news-column .newslist li {
    padding-left: 0px;
    margin-bottom: 30px;
    height: auto;
  }
}

.login-window-AvantIT .login-area .news-column .newslist li p {
  font-size: 16px;
  color: #ffffff;
}

.login-window-AvantIT .login-area .news-column .newslist li .date {
  color: #e3ef1c;
}

.login-window-AvantIT .login-area .news-column .newslist li img {
  border: 2px solid #ffffff;
  width: 192px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .login-window-AvantIT .login-area .news-column .newslist li img {
    position: relative;
    margin-bottom: 15px;
  }
}
/*AvantIT Login Page CSS-End*/

/*Osloadvokatene Login Page CSS-Start*/
.login-window-Osloadvokatene {
  background-color: #122e43;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-window-Osloadvokatene .login-redirect-area {
  width: 640px;
}

@media screen and (max-width: 680px) {
  .login-window-Osloadvokatene .login-redirect-area {
    width: 90%;
  }
  .login-window-Osloadvokatene .login-redirect-area img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0px;
    min-height: 100%;
    height: auto;
  }
}
.login-window-Osloadvokatene .login-area {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 930px;
}

@media screen and (max-width: 940px) {
  .login-window-Osloadvokatene .login-area {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene .login-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.login-window-Osloadvokatene .login-area .login-column,
.login-window-Osloadvokatene .login-area .news-column {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene .login-area .login-column,
  .login-window-Osloadvokatene .login-area .news-column {
    width: 100%;
  }
}

.login-window-Osloadvokatene .login-area .login-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  padding: 40px 40px 20px 40px;
}

@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene .login-area .login-column {
    padding: 40px 30px 20px 30px;
  }
}

.login-window-Osloadvokatene .login-area .login-column .vippsbtn {
  background: none;
  border: none;
}

.login-window-Osloadvokatene .login-area .login-column .vippsbtn:focus, .login-window .login-area .login-column .vippsbtn:hover {
  background: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.login-window-Osloadvokatene .login-area .login-column p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.login-window-Osloadvokatene .login-area .login-column p.fnt-14 {
  font-size: 14px;
}

.login-window-Osloadvokatene .login-area .login-column p.fnt-14 a {
  color: #122e43;
}

.login-window-Osloadvokatene .login-area .login-column button {
  background-color: #fe5a22;
  padding: 15px 0px;
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene .login-area .login-column button {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .login-window-Osloadvokatene .login-area .login-column button {
    width: 100%;
  }
}

.login-window-Osloadvokatene .login-area .news-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px 20px 40px;
  background-color: #2c5f85;
  border-radius: 0px 4px 4px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 768px) {
  .login-window-Osloadvokatene .login-area .news-column {
    padding: 30px 20px;
    border-radius: 0px 0px 4px 4px;
  }
}

.login-window-Osloadvokatene .login-area .news-column h3 {
  font-size: 20px;
  color: #ffffff;
}

.login-window-Osloadvokatene .login-area .news-column .newslist li {
  position: relative;
  padding-left: 207px;
  height: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .login-window-Osloadvokatene .login-area .news-column .newslist li {
    padding-left: 0px;
    margin-bottom: 30px;
    height: auto;
  }
}

.login-window-Osloadvokatene .login-area .news-column .newslist li p {
  font-size: 16px;
  color: #ffffff;
}

.login-window-Osloadvokatene .login-area .news-column .newslist li .date {
  color: #e3ef1c;
}

.login-window-Osloadvokatene .login-area .news-column .newslist li img {
  border: 2px solid #ffffff;
  width: 192px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .login-window-Osloadvokatene .login-area .news-column .newslist li img {
    position: relative;
    margin-bottom: 15px;
  }
}
/*Osloadvokatene Login Page CSS-End*/


/*Strandenæs Login Page CSS-Start*/
.login-window-Strandenæs{
  background-color: #e5e5e5;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-window-Strandenæs .login-redirect-area {
  width: 640px;
}

@media screen and (max-width: 680px) {
  .login-window-Strandenæs .login-redirect-area {
    width: 90%;
  }
  .login-window-Strandenæs .login-redirect-area img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .login-window-Strandenæs{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0px;
    min-height: 100%;
    height: auto;
  }
}
.login-window-Strandenæs .login-area {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 930px;
}

@media screen and (max-width: 940px) {
  .login-window-Strandenæs .login-area {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .login-window-Strandenæs .login-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.login-window-Strandenæs .login-area .login-column,
.login-window-Strandenæs .login-area .news-column {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .login-window-Strandenæs .login-area .login-column,
  .login-window-Strandenæs .login-area .news-column {
    width: 100%;
  }
}

.login-window-Strandenæs .login-area .login-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  padding: 40px 40px 20px 40px;
}

@media screen and (max-width: 768px) {
  .login-window-Strandenæs .login-area .login-column {
    padding: 40px 30px 20px 30px;
  }
}

.login-window-Strandenæs .login-area .login-column .vippsbtn {
  background: none;
  border: none;
}

.login-window-Strandenæs .login-area .login-column .vippsbtn:focus, .login-window .login-area .login-column .vippsbtn:hover {
  background: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.login-window-Strandenæs .login-area .login-column p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.login-window-Strandenæs .login-area .login-column p.fnt-14 {
  font-size: 14px;
}

.login-window-Strandenæs .login-area .login-column p.fnt-14 a {
  color: #122e43;
}

.login-window-Strandenæs .login-area .login-column button {
  background-color: #fe5a22;
  padding: 15px 0px;
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .login-window-Strandenæs .login-area .login-column button {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .login-window-Strandenæs .login-area .login-column button {
    width: 100%;
  }
}

.login-window-Strandenæs .login-area .news-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px 20px 40px;
  background-color: #737373;
  border-radius: 0px 4px 4px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 768px) {
  .login-window-Strandenæs .login-area .news-column {
    padding: 30px 20px;
    border-radius: 0px 0px 4px 4px;
  }
}

.login-window-Strandenæs .login-area .news-column h3 {
  font-size: 20px;
  color: #ffffff;
}

.login-window-Strandenæs .login-area .news-column .newslist li {
  position: relative;
  padding-left: 207px;
  height: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .login-window-Strandenæs .login-area .news-column .newslist li {
    padding-left: 0px;
    margin-bottom: 30px;
    height: auto;
  }
}

.login-window-Strandenæs .login-area .news-column .newslist li p {
  font-size: 16px;
  color: #ffffff;
}

.login-window-Strandenæs .login-area .news-column .newslist li .date {
  color: #e3ef1c;
}

.login-window-Strandenæs .login-area .news-column .newslist li img {
  border: 2px solid #ffffff;
  width: 192px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .login-window-Strandenæs .login-area .news-column .newslist li img {
    position: relative;
    margin-bottom: 15px;
  }
}
/*Strandenæs Login Page CSS-End*/


/*Kreftforeningen Login Page CSS-Start*/
.login-window-Kreftforeningen {
  background-color: #333333;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-window-Kreftforeningen .login-redirect-area {
  width: 640px;
}

@media screen and (max-width: 680px) {
  .login-window-Kreftforeningen .login-redirect-area {
    width: 90%;
  }
  .login-window-Kreftforeningen .login-redirect-area img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0px;
    min-height: 100%;
    height: auto;
  }
}
.login-window-Kreftforeningen .login-area {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 930px;
}

@media screen and (max-width: 940px) {
  .login-window-Kreftforeningen .login-area {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen .login-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.login-window-Kreftforeningen .login-area .login-column,
.login-window-Kreftforeningen .login-area .news-column {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen .login-area .login-column,
  .login-window-Kreftforeningen .login-area .news-column {
    width: 100%;
  }
}

.login-window-Kreftforeningen .login-area .login-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  padding: 40px 40px 20px 40px;
}

@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen .login-area .login-column {
    padding: 40px 30px 20px 30px;
  }
}

.login-window-Kreftforeningen .login-area .login-column .vippsbtn {
  background: none;
  border: none;
}

.login-window-Kreftforeningen .login-area .login-column .vippsbtn:focus, .login-window .login-area .login-column .vippsbtn:hover {
  background: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.login-window-Kreftforeningen .login-area .login-column p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.login-window-Kreftforeningen .login-area .login-column p.fnt-14 {
  font-size: 14px;
}

.login-window-Kreftforeningen .login-area .login-column p.fnt-14 a {
  color: #500e6a;
}

.login-window-Kreftforeningen .login-area .login-column button {
  background-color: #fe5a22;
  padding: 15px 0px;
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen .login-area .login-column button {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .login-window-Kreftforeningen .login-area .login-column button {
    width: 100%;
  }
}

.login-window-Kreftforeningen .login-area .news-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px 20px 40px;
  background-color: #2c5f85;
  border-radius: 0px 4px 4px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 768px) {
  .login-window-Kreftforeningen .login-area .news-column {
    padding: 30px 20px;
    border-radius: 0px 0px 4px 4px;
  }
}

.login-window-Kreftforeningen .login-area .news-column h3 {
  font-size: 20px;
  color: #ffffff;
}

.login-window-Kreftforeningen .login-area .news-column .newslist li {
  position: relative;
  padding-left: 207px;
  height: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .login-window-Kreftforeningen .login-area .news-column .newslist li {
    padding-left: 0px;
    margin-bottom: 30px;
    height: auto;
  }
}

.login-window-Kreftforeningen .login-area .news-column .newslist li p {
  font-size: 16px;
  color: #ffffff;
}

.login-window-Kreftforeningen .login-area .news-column .newslist li .date {
  color: #e3ef1c;
}

.login-window-Kreftforeningen .login-area .news-column .newslist li img {
  border: 2px solid #ffffff;
  width: 192px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .login-window-Kreftforeningen .login-area .news-column .newslist li img {
    position: relative;
    margin-bottom: 15px;
  }
}
/*Kreftforeningen Login Page CSS-End*/

/*Insa Login Page CSS-Start*/
.login-window-Insa {
  background-color: #500e6a;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-window-Insa .login-redirect-area {
  width: 640px;
}

@media screen and (max-width: 680px) {
  .login-window-Insa .login-redirect-area {
    width: 90%;
  }
  .login-window-Insa .login-redirect-area img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .login-window-Insa{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0px;
    min-height: 100%;
    height: auto;
  }
}
.login-window-Insa .login-area {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 930px;
}

@media screen and (max-width: 940px) {
  .login-window-Insa .login-area {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .login-window-Insa .login-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.login-window-Insa .login-area .login-column,
.login-window-Insa .login-area .news-column {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .login-window-Insa .login-area .login-column,
  .login-window-Insa .login-area .news-column {
    width: 100%;
  }
}

.login-window-Insa .login-area .login-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  padding: 40px 40px 20px 40px;
}

@media screen and (max-width: 768px) {
  .login-window-Insa .login-area .login-column {
    padding: 40px 30px 20px 30px;
  }
}

.login-window-Insa .login-area .login-column .vippsbtn {
  background: none;
  border: none;
}

.login-window-Insa .login-area .login-column .vippsbtn:focus, .login-window .login-area .login-column .vippsbtn:hover {
  background: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.login-window-Insa .login-area .login-column p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.login-window-Insa .login-area .login-column p.fnt-14 {
  font-size: 14px;
}

.login-window-Insa .login-area .login-column p.fnt-14 a {
  color: #500e6a;
}

.login-window-Insa .login-area .login-column button {
  background-color: #fe5a22;
  padding: 15px 0px;
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .login-window-Insa .login-area .login-column button {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .login-window-Insa .login-area .login-column button {
    width: 100%;
  }
}

.login-window-Insa .login-area .news-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px 20px 40px;
  background-color: #e1c8ff;
  border-radius: 0px 4px 4px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 768px) {
  .login-window-Insa .login-area .news-column {
    padding: 30px 20px;
    border-radius: 0px 0px 4px 4px;
  }
}

.login-window-Insa .login-area .news-column h3 {
  font-size: 20px;
  color: #ffffff;
}

.login-window-Insa .login-area .news-column .newslist li {
  position: relative;
  padding-left: 207px;
  height: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .login-window-Insa .login-area .news-column .newslist li {
    padding-left: 0px;
    margin-bottom: 30px;
    height: auto;
  }
}

.login-window-Insa .login-area .news-column .newslist li p {
  font-size: 16px;
  color: #ffffff;
}

.login-window-Insa .login-area .news-column .newslist li .date {
  color: #e3ef1c;
}

.login-window-Insa .login-area .news-column .newslist li img {
  border: 2px solid #ffffff;
  width: 192px;
  max-width: 100%;
  height: auto;
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .login-window-Insa .login-area .news-column .newslist li img {
    position: relative;
    margin-bottom: 15px;
  }
}
/*Insa Login Page CSS-End*/









.loginbankid{
    display: block;
    width: 248px;
    height: 40px;
    background: #4E9CAF;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
    margin: auto;
    margin-top: 5px;
}
